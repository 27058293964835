// src/gatsby-theme-apollo/client.js
// https://www.gatsbyjs.com/plugins/gatsby-theme-apollo/

import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: 'https://dashboard.mamas-co.com/.netlify/functions/graphql',
    fetch
  })
});




export default client;
