import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider,ThemeProvider, jssPreset } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';

import { create } from 'jss';
import rtl from 'jss-rtl'

import theme from '../../src/theme';
import client from '../../src/client';



const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});




export default function TopLayout(props) {
  return (
    <ApolloProvider client={client}>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </StylesProvider>
    </ApolloProvider>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
